<template>
  <div class="ware">
    <!-- <div class="ls_flex top_info" v-if="isTeacher">
            <div>
                <div>平台课件服务</div>
                <div>国赛大师倾力制作，虚实作品解析长期更新</div>
            </div>
            <div @click="$router.push({ path: '/future' })">
                <div>自建课件（即将开放）</div>
                <div>复现经典作品、创作教学课件</div>
            </div>
        </div> -->
    <div class="ls_flexalign_center search_part">
      <el-date-picker
        v-model="params.dateRange"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd HH:mm:ss"
        size="medium"
        style="width: 380px"
      ></el-date-picker>
      <el-select v-model="params.publishStatus">
        <el-option
          v-for="item in statusOptions"
          :key="item.label"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-input
        v-model.trim="params.title"
        placeholder="请输入课程名称"
      ></el-input>
      <el-button plain @click="(params.pageIndex = 1), getList()">
        查询
      </el-button>
      <el-button type="primary" @click="edit()" v-if="isTeacher">
        创建课件
      </el-button>
    </div>
    <el-table
      :data="list"
      style="width: 100%"
      :header-cell-style="{ background: '#FAFAFA' }"
      v-if="isTeacher"
    >
      <el-table-column prop="title" label="课件名称"></el-table-column>
      <el-table-column label="描述" width="240">
        <template slot-scope="scope">
          <div :title="scope.row.coursewareDesc" class="desc">
            {{ scope.row.coursewareDesc }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="发布范围">
        <template slot-scope="scope">
          <span>
            {{ scope.row.groupNames && scope.row.groupNames.join(",") }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="publishTime" label="发布时间" width="180">
        <template slot-scope="scope">
          {{ scope.row.publishTime || "-" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="completeNumber"
        label="查看人数"
        width="100"
      ></el-table-column>
      <el-table-column label="状态" width="80">
        <template slot-scope="scope">
          {{ scope.row.publishStatus === 1 ? "已发布" : "未发布" }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <div class="opt_part">
            <span
              @click="
                video.item = scope.row;
                video.show = true;
              "
            >
              查看
            </span>
            <template v-if="scope.row.publishStatus === 0">
              <span @click="release(scope.row.id)"> 发布 </span>
              <span @click="edit(scope.row)">编辑</span>
            </template>
            <template v-else>
              <span @click="back(scope.row.id)">撤回</span>
              <span @click="toDataAnalysis(scope.row.id)">数据分析</span>
            </template>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-table
      :data="list"
      style="width: 100%"
      :header-cell-style="{ background: '#FAFAFA' }"
      v-else
    >
      <el-table-column prop="title" label="课件名称"></el-table-column>
      <el-table-column prop="createTime" label="发布时间"></el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          {{ scope.row.learnStatus === 1 ? "已学习" : "未学习" }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div class="opt_part">
            <span @click="open(scope.row)">查看</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: center; margin-top: 15px">
      <el-pagination
        :current-page.sync="params.pageIndex"
        :page-sizes="[10, 15, 20, 25]"
        :page-size.sync="params.pageSize"
        :total="total"
        background
        layout="total, sizes, prev, pager, next"
        @size-change="
          params.pageIndex = 1;
          getList();
        "
        @current-change="getList"
      />
    </div>
    <el-dialog
      :title="courseInfo.id ? '编辑课件' : '创建课件'"
      :visible.sync="courseInfo.show"
      :close-on-click-modal="false"
      width="800px"
    >
      <el-form ref="form" label-width="100px">
        <el-form-item label="课件标题">
          <el-input
            v-model="courseInfo.title"
            placeholder="请输入课件标题"
            maxlength="50"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="课件视频地址">
          <el-input
            v-model="courseInfo.url"
            placeholder="请输入课件视频地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="课件描述">
          <el-input
            placeholder="请输入课件描述"
            :autosize="{ minRows: 5, maxRows: 100 }"
            v-model="courseInfo.coursewareDesc"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="发布对象">
          <div style="padding-top: 10px">
            <el-checkbox-group v-model="courseInfo.groupIds">
              <el-checkbox
                v-for="item in courseList"
                :label="item.id"
                :key="item.id"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </el-form-item>
        <el-form-item label="">
          <el-button @click="courseInfo.show = false">取消</el-button>
          <el-button type="primary" @click="confirm">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      :title="video.edit ? '编辑' : '查看'"
      :visible.sync="video.show"
      :close-on-click-modal="false"
      width="800px"
      @closed="close"
    >
      <video
        class="mb20"
        id="ls_vedio"
        autoplay="true"
        controls="true"
        v-if="video.show"
      >
        <source :src="video.item.url" type="video/mp4" />
      </video>
      <template v-if="!video.edit">
        <div class="mb20">课件名称：{{ video.item.title }}</div>
        <div class="mb20">描述：{{ video.item.coursewareDesc }}</div>
        <div>
          发布对象：{{
            video.item.groupNames && video.item.groupNames.join(",")
          }}
        </div>
      </template>
      <template v-else>
        <el-input
          class="mb20"
          v-model="video.item.title"
          placeholder="请输入教师名称"
        />
        <el-input
          class="mb20"
          v-model="video.item.coursewareDesc"
          type="textarea"
          placeholder="请输入教师名称"
        />
        <el-checkbox-group class="mb20" v-model="video.item.groupIds">
          <el-checkbox
            v-for="item in courseList"
            :label="item.id"
            :key="item.id"
            >{{ item.name }}</el-checkbox
          >
        </el-checkbox-group>
        <div>
          <el-button size="medium" type="success" @click="submitFunc('send')">
            发布课件
          </el-button>
          <el-button size="medium" @click="submitFunc()"> 保存 </el-button>
          <el-button size="medium" @click="video.show = false">
            取消
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  data() {
    return {
      courseInfo: {
        show: false,
        id: "",
        title: "",
        url: "",
        coursewareDesc: "",
        groupIds: [],
      },
      learId: "",
      video: {
        show: false,
        item: "",
        edit: false,
      },
      list: [],
      total: 0,
      courseList: [],
      params: {
        dateRange: [],
        publishStatus: "",
        title: "",
        addType: "", // 自建课件2
        pageIndex: 1,
        pageSize: 10,
      },
    };
  },
  created() {
    this.$ajax.post("/user/group/getList", {}).then((response) => {
      this.courseList = JSON.parse(
        JSON.stringify(response.data.filter((item) => item.stuCount))
      );
    });
    let nowTime = dayjs(new Date());
    // this.params.dateRange = [
    //   nowTime.subtract(3, "month").format("YYYY-MM-DD HH:mm:ss"),
    //   nowTime.format("YYYY-MM-DD HH:mm:ss"),
    // ];
    this.getList();
  },
  methods: {
    confirm() {
      if (!this.courseInfo.title) {
        this.$message.warning("请输入标题");
        return;
      }
      if (!this.courseInfo.url) {
        this.$message.warning("请输入视频地址");
        return;
      }
      if (!this.courseInfo.coursewareDesc) {
        this.$message.warning("请输入描述");
        return;
      }
      if (!this.courseInfo.groupIds.length) {
        this.$message.warning("请选择发布范围");
        return;
      }

      this.$ajax
        .post("/coursewareOrganization/save", {
          ...this.courseInfo,
          groupIds: this.courseInfo.groupIds.join(","),
        })
        .then(() => {
          this.$message.success("创建成功");
          this.courseInfo.show = false;
          this.getList();
        });
    },
    editVideo(item) {
      try {
        this.video.item = {
          ...item,
          groupIds: item.groupIds.split(",").map((item) => item - 0),
        };
      } catch (e) {
        this.video.item = { ...item, groupIds: [] };
      }
      this.video.show = true;
      this.video.edit = true;
    },
    submitFunc(type) {
      this.$ajax
        .post("/coursewareOrganization/save", {
          id: this.video.item.id,
          title: this.video.item.title,
          url: this.video.item.url,
          coursewareDesc: this.video.item.coursewareDesc,
          groupIds: this.video.item.groupIds.join(","),
        })
        .then((res) => {
          if (type == "send") {
            this.$ajax
              .post("/coursewareOrganization/publish", {
                id: res.data,
                publishStatus: 1,
              })
              .then((val) => {
                this.getList();
                this.video.show = false;
              });
          } else {
            this.getList();
            this.video.show = false;
          }
        });
    },
    close() {
      if (this.learId) {
        window.clearInterval(this.learId);
        this.learId = "";
      }
    },
    open(item) {
      this.video.item = item;
      this.video.show = true;
      if (!this.isTeacher) {
        this.learId = setInterval(() => {
          this.$ajax
            .get("/coursewareStudent/learn?id=" + item.id)
            .then((res) => {
              this.getList();
            });
        }, 60000);
      }
    },
    //发布
    release(id) {
      this.$confirm("您确定发布课件吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$ajax
          .post("/coursewareOrganization/publish", {
            id,
            publishStatus: 1,
          })
          .then((res) => {
            this.$message({
              type: "success",
              message: "发布成功!",
            });
            this.getList();
          });
      });
    },
    //回撤
    back(id) {
      this.$confirm("撤回后对应学生将无法继续观看, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$ajax
          .post("/coursewareOrganization/publish", {
            id,
            publishStatus: 0,
          })
          .then((res) => {
            this.$message({
              type: "success",
              message: "撤回成功!",
            });
            this.getList();
          });
      });
    },
    getList() {
      this.$ajax
        .post(
          this.isTeacher
            ? "/coursewareOrganization/pageList"
            : "/coursewareStudent/pageList",
          {
            ...this.params,
            startTime: this.params.dateRange && this.params.dateRange[0],
            endTime: this.params.dateRange && this.params.dateRange[1],
          }
        )
        .then((res) => {
          this.list = res.list;
          this.total = res.total;
        });
    },
    toDataAnalysis(id) {
      this.$router.push({ path: "/courseware/dataAnalysis?id=" + id });
    },
    edit(item) {
      if (item) {
        this.courseInfo = {
          show: true,
          id: item.id,
          title: item.title,
          url: item.url,
          coursewareDesc: item.coursewareDesc,
          groupIds: item.groupIds.split(",").map((item) => item - 0),
        };
      } else {
        this.courseInfo = {
          show: true,
          id: "",
          title: "",
          url: "",
          coursewareDesc: "",
          groupIds: [],
        };
      }
    },
  },
  computed: {
    statusOptions() {
      return this.isTeacher
        ? [
            { label: "全部", value: "" },
            { label: "已发布", value: 1 },
            { label: "未发布", value: 0 },
          ]
        : [
            { label: "全部", value: "" },
            { label: "已学习", value: 1 },
            { label: "未学习", value: 0 },
          ];
    },
  },
};
</script>

<style lang="scss" scoped>
#ls_vedio {
  width: 100%;
  height: 50vh;
}

.desc {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search_part {
  > * {
    + * {
      margin-left: 7px;
    }

    &:first-child(1),
    &:nth-child(2) {
      width: 120px;
    }

    &:nth-child(3) {
      width: 200px;
    }
  }

  margin-bottom: 15px;
}

.ware {
  padding: 20px 20px 20px 0;
}

.top_info {
  width: 100%;
  margin-bottom: 15px;

  > div {
    flex: 1;
    padding: 30px 0;
    border: 1px solid #999;
    border-radius: 10px;
    text-align: center;

    + div {
      margin-left: 50px;
    }

    &:first-child {
      background: #8bb229;
      border-color: #8bb229;
      color: #fff;
    }

    > div {
      &:first-child {
        font-size: 30px;
      }

      &:last-child {
        font-size: 16px;
        margin-top: 15px;
      }
    }
  }
}
</style>
